import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AnalisisFalla = ({ data }) => {
  const { header, main } = data.allServiciosDataJson.edges[0].node
  return (
    <Layout location="/servicios">
      <SEO title="Análisis de Falla" />
      <div className="servicios-main-wrap">
        <div
          className="servicios-header py-3 falla"
          /* style={{
            backgroundImage: `url(${header.bg.childImageSharp.fluid.src})`,
          }} */
        >
          <div className="container">
            <div className="row align-items-center h-100">
              <div className="col-12">
                <h2 className="text-center title">{header.title}</h2>
                <p>{header.text}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="servicios-content">
          <div
            className="content-background falla"
            /* style={{
              backgroundImage: `url(${main.bg.childImageSharp.fluid.src})`,
            }} */
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {main.content.map((e, i) => {
                  if (e.type === "p") {
                    return <p key={`content-${i}`}>{e.content}</p>
                  }
                  if (e.type === "ul") {
                    return (
                      <React.Fragment key={`content-${i}`}>
                        <p>{e.title}</p>
                        <ul>
                          {e.list.map((listItem, index) => (
                            <li key={`item-${i}.${index}`}>{listItem}</li>
                          ))}
                        </ul>
                      </React.Fragment>
                    )
                  }
                  if (e.type === "ol") {
                    return (
                      <React.Fragment key={`content-${i}`}>
                        <p>{e.title}</p>
                        <ol>
                          {e.list.map((listItem, index) => (
                            <li key={`item-${i}.${index}`}>{listItem}</li>
                          ))}
                        </ol>
                      </React.Fragment>
                    )
                  }
                  return <div key={`falla-${e.type}-${i}`}></div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allServiciosDataJson(filter: { data_route: { eq: "analisis_falla" } }) {
      edges {
        node {
          data_route
          header {
            text
            title
            bg {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
          main {
            content {
              content
              list
              title
              type
            }
            bg {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AnalisisFalla
